import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import HomePage7 from '../HomePage7/HomePage7';
import AboutPage from '../AboutPage/AboutPage';
import ServicePageS3 from '../ServicePageS3/ServicePageS3';
import ContactPage from '../ContactPage/ContactPage';



const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage7 />} />
          <Route path="naslovnica" element={<HomePage7 />} />
          <Route path="o-nama" element={<AboutPage />} />
          <Route path="usluge" element={<ServicePageS3 />} />
          <Route path='kontakt' element={<ContactPage />} />

        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
