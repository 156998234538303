import React from 'react'
import { Link } from 'react-router-dom'
import abimg from '../../images/about/ab2.png'

const ClickHandler = (props) => {
    window.scrollTo(10, 0);
}


const About4 = (props) => {
    return (
        <section className="wpo-about-section-s4 section-padding pb-120">
            <div className="container">
                <div className="wpo-about-inner">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-5 col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-text">
                                <h4>Dobrodošli u MMH Consulting</h4>
                                <h2>Optimalno rješenje za poslovno i financijsko planiranje.</h2>
                                <p>Ono što naši klijenti mogu očekivati je: precizna računovodstvena obrada, pouzdanost u ispunjavanju rokova, ažurno savjetovanje u vezi s poslovanjem te pravovremeno informiranje. Naš tim posvećen je obavljanju poslova s profesionalnošću i stručnošću, te svim potrebnim naporima kako bismo osigurali zadovoljstvo klijenata.</p>
                                <div className="text-sub-wrap">
                                    <div className="text-sub-item">
                                        <i className="fi flaticon-tax"></i>
                                        <h5>Vaše poslovanje u sigurnim rukama.</h5>
                                    </div>
                                </div>
                                <Link onClick={ClickHandler} className="theme-btn" to="/usluge">Naše usluge</Link>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-wrap">
                                <div className="wpo-about-img">
                                    <div className="inner-img">
                                        <img src={abimg} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-ab"></div>
        </section>
    )
}

export default About4;