import React from "react";
import { Link } from 'react-router-dom'
import hero1 from '../../images/slider/33.png'
import shape1 from '../../images/slider/shape-6.png'
import shape2 from '../../images/slider/shape-7.png'
import Fade from 'react-reveal/Fade';

const Hero6 = () => {
    return (
        <section className="static-hero-s3">
            <div className="hero-container">
                <div className="hero-inner">
                    <div className="container">
                        <div className="hero-content">
                            <div className="slide-title-sub">
                                <h5>Obratite nam se s povjerenjem</h5>
                            </div>
                            {/* Upravljanje Financijama: Rješenja Pripravna za Vaše Potrebe */}
                            <div className="slide-title">
                                <h2>Knjigovodstvene usluge: <b><span>Najbolja </span>rješenja za vaše poslovanje.</b></h2>
                            </div>
                            <div className="slide-text">
                                <p>Mikro i makro poduzeća, obrti, paušali i OPG-ovi.</p>
                            </div>
                            <div className="clearfix"></div>
                            <div className="btns">
                                <Link to="/o-nama" className="theme-btn">Istraži više</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Fade right>
                <div className="consult-pic">
                    <img src={hero1} alt="" />
                </div>
            </Fade>
            <div className="hero-line-shape-1"><img src={shape1} alt="" /></div>
            <div className="hero-line-shape-2"><img src={shape2} alt="" /></div>
        </section>
    )
}

export default Hero6;