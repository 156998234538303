import simg from '../images/service/1.jpg'
import simg2 from '../images/service/2.jpg'
import simg3 from '../images/service/3.jpg'
import simg4 from '../images/service/4.jpg'

import sImgS1 from '../images/service-single/img-1.jpg'
import sImgS2 from '../images/service-single/img-2.jpg'
import sImgS3 from '../images/service-single/img-3.jpg'
import sImgS4 from '../images/service-single/img-4.jpg'

import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'




const Services = [
    {
        Id: '1',
        sImg: simg,
        sImgS: sImgS1,
        sTitle: 'Knjigovodstvene i administrativne usluge',
        description: 'Podrška poslovanju kroz vođenje knjiga, financijske izvještaje, administrativne zadatke i usklađenost sa zakonima.',
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-taxes',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
    },
    {
        Id: '2',
        sImg: simg2,
        sImgS: sImgS2,
        sTitle: 'Poslovno savjetovanje',
        description: 'Pružanje stručnih savjeta i strategija kako bi klijenti unprijedili performanse, razvili poslovne strategije i ostvarili ciljeve.',
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-team',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
    },
    {
        Id: '3',
        sImg: simg3,
        sImgS: sImgS3,
        sTitle: 'Virtualna asistencija u knjigovodstvu i računovodstvu',
        description: 'Pružanje podrške putem online alata i tehnologija, olakšavajući klijentima vođenje knjiga, upravljanje financijama i ostale administrativne zadatke.',
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-analytics',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
    },
    {
        Id: '4',
        sImg: simg4,
        sImgS: sImgS4,
        sTitle: 'Pomoć oko dobivanja dozvola za boravak i rad',
        description: 'Pružanje stručne podrške našim klijentima u procesu apliciranja i dobivanja potrebnih dozvola za boravak i zapošljavanje u Hrvatskoj ili u inozemstvu.',
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-email-1',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
    },

]

export default Services;