import React from 'react'


const MissionVission = (props) => {
    return (
        <section className="wpo-mission-vission-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-mission-item">
                            <div className="wpo-mission-text">
                                <h2>Naša misija</h2>
                                <p>Naša misija u knjigovodstvu i računovodstvu je jednostavna: pružiti pouzdanu podršku poslovanju. Kroz profesionalnost i preciznost, težimo olakšati financijske procese.</p>
                                <h5>Uz osigurivanje uspjeha naših klijenata, nastojimo unaprijediti njihove poslovne rezultate. Vaša sigurnost i zadovoljstvo su naša najveća motivacija.</h5>
                            </div>
                            <div className="shape-1"></div>

                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-vission-item">
                            <div className="wpo-vission-text">
                                <h2>Naša vizija</h2>
                                <h5>Naša vizija u knjigovodstvu i računovodstvu je kroz inovativne pristupe i neprekidno usavršavanje postaviti nove standarde kvalitete i pouzdanosti.</h5>
                                <p>Vidimo se kao pokretači promjena koji će oblikovati budućnost poslovnog svijeta. Želimo biti prepoznati po izvrsnosti i integritetu, ostavljajući trajni utjecaj na naše klijente i industriju.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MissionVission;