import React, { Fragment } from 'react';
import About3 from '../../components/about3/about3';
import Footer from '../../components/footer/Footer';
import Hero6 from '../../components/hero6/Hero6';
import Navbar3 from '../../components/Navbar3/Navbar3';
import Scrollbar from '../../components/scrollbar/scrollbar';
import ServiceSectionS3 from '../../components/ServiceSectionS3/ServiceSectionS3';

import Logo from '../../images/logo.png'

const HomePage7 = () => {
    return (
        <Fragment>
            <Navbar3 Logo={Logo} topbarNone={'wpo-header-style-6'} />
            <Hero6 />
            <About3 />
            <ServiceSectionS3 />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default HomePage7;