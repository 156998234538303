import React, { Fragment } from 'react';
import Navbar3 from '../../components/Navbar3/Navbar3';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import MissionVission from '../../components/MissionVission/MissionVission';
import About4 from '../../components/about4/about4';
import Footer from '../../components/footer/Footer';


const AboutPage = () => {
    return (
        <Fragment>
            <Navbar3 />
            <PageTitle pageTitle={'O nama'} pagesub={'O nama'} />
            <MissionVission />
            <About4 />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default AboutPage;
