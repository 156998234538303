import React, { Fragment } from 'react';
import Navbar3 from '../../components/Navbar3/Navbar3';
import PageTitle from '../../components/pagetitle/PageTitle'
import Contactpage from '../../components/Contactpage/Contactpage'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Logo from '../../images/logo.png'
import Footer from '../../components/footer/Footer';

const ContactPage = () => {
    return (
        <Fragment>
            <Navbar3 Logo={Logo} />
            <PageTitle pageTitle={'Kontaktirajte nas'} pagesub={'Kontakt'} />
            <Contactpage />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default ContactPage;

